import { ExclamationCircleIcon } from '@heroicons/react/solid'
import queryKeys from 'config/queries'
import useUploadExcel from 'hooks/useUploadExcel'
import { useForm } from "react-hook-form"
import toast from 'react-hot-toast'
import { useQueryClient } from "react-query"


interface UserAccessModalProps {
    onClose: () => void
}

const UploadProvidersModal = ({ onClose }: UserAccessModalProps) => {

    const { register, handleSubmit, reset: resetForm } = useForm()

    const handleDownloadTemplate = () => {
        window.open(`${process.env.REACT_APP_API_URL}/templates/providers_template.xlsx`, "_blank")
        //https://api.supplierdirectory.decathlontools.co/templates/providers_template.xlsx
    }

    const queryClient = useQueryClient()

    const { uploadExcelFile, isLoading, isSuccess, isError, reset } = useUploadExcel("/providers/massive", () => {
        queryClient.invalidateQueries(queryKeys.PROVIDERS)
        toast.dismiss()
        toast.success("Archivo cargado.", { style: { background: "#3643BA", color: "white" } })
        handleClose()
    })

    const onSubmit = (data: any) => uploadExcelFile(data.files[0])

    const handleClose = () => {
        onClose()
        resetForm()
        reset()
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white shadow-lg max-w-md w-full p-6 relative">
                <h2 className="absolute top-0 left-0 w-full text-lg font-semibold text-white bg-dkt-brand-blue p-6">
                    Creación masiva de proveedores
                </h2>

                <div className="mt-20 border-l-4 border-dkt-brand-blue bg-blue-50 p-4">
                    <div className="flex">
                        <div className="shrink-0">
                            <ExclamationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-blue-700">
                                Descarga el template excel{' '}
                                <button className="font-medium text-blue-700 underline hover:text-yellow-600" onClick={handleDownloadTemplate}>
                                    aquí
                                </button>
                                {' '}y diligenciado con la información requerida de los proveedores.
                            </p>
                        </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Selecciona template
                        </label>
                        <input
                            type="file"
                            {...register("files", { required: true })}
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={handleClose}
                            disabled={isLoading}
                            className="px-4 py-2 bg-gray-200 text-gray-800 hover:bg-gray-300"
                        >
                            Cerrar
                        </button>
                        <button
                            className="ml-2 px-4 py-2 bg-dkt-brand-blue text-white hover:bg-blue-700"
                            type="submit"
                        >
                            Confirmar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UploadProvidersModal