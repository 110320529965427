import useSession from "hooks/useSession";

const Header = () => {

    const { user, logout } = useSession()

    return (
        <header className="bg-white">
            <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8">
                <div className="flex lg:flex-1">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">Your Company</span>
                        <img
                            alt=""
                            src="https://trabajaconnosotros.decathlon.es/wp-content/uploads/2024/03/decathlon-logo-blue.png"
                            className="h-8 w-auto"
                        />
                    </a>
                </div>
                <div className="flex flex-1 items-center justify-end gap-x-6">
                    <button
                        onClick={logout}
                        className=" bg-dkt-brand-blue px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Cerrar sesión
                    </button>
                </div>
            </nav>
        </header>
    )
}

export default Header