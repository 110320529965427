import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import Provider from "types/Provider"
import axios from "utils/axios"
import queryKeys from "config/queries"
import ProvidersList from "./ProvidersList"
import Spinner from "components/Spinner/Spinner"
import Pagination from "v2/components/Pagination"




interface ProvidersApiResponse {
    page: Number,
    providers: Array<Provider>
    numberOfItems: number
}

const PAGINATION_STEP = 20

const Providers = () => {

    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")

    const { data, isLoading, isFetching } = useQuery([queryKeys.PROVIDERS, search, page, PAGINATION_STEP], () => axios.get<ProvidersApiResponse>(`/providers`, {
        params: {
            page,
            search,
            limit: PAGINATION_STEP
        }
    }).then(res => res.data))

    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setSearch(searchTerm)
            setPage(1)
        }, 250)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    return (
        <>
            <div className="relative w-full gap-x-6 p-6 lg:px-8 max-w-7xl mx-auto">
                <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    name="search"
                    id="search"
                    type="text"
                    placeholder="Buscar..."
                    className="w-full py-2 pl-4 pr-10 text-gray-700 border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    {
                        search && isLoading && <Spinner size="small" />
                    }
                </div>
            </div>

            <ProvidersList providers={data?.providers} />

            {data && <Pagination page={page} onChange={setPage} total={data.numberOfItems} step={PAGINATION_STEP} />}

        </>
    )
}

export default Providers