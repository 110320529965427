interface PaginationProps {
    page: number
    onChange: (page: number) => void
    total: number
    step: number
}

const Pagination = ({ page, onChange, total, step }: PaginationProps) => {

    const nextButtonDisabled = (page) >= total / step
    const previousButtonDisabled = page == 1

    return (
        <nav
            aria-label="Pagination"
            className="flex items-center justify-between border-t border-gray-200 bg-white gap-x-6 p-6 lg:px-8 max-w-7xl mx-auto"
        >
            <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                    Mostrando del <span className="font-medium">{(page - 1) * step + 1}</span> al <span className="font-medium">{Math.min((page - 1) * step + step, total)}</span> de{' '}
                    <span className="font-medium">{total}</span> proveedores
                </p>
            </div>
            <div className="flex flex-1 justify-between sm:justify-end">
                <button
                    onClick={() => onChange(page - 1)}
                    disabled={previousButtonDisabled}
                    className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 ${
                        previousButtonDisabled ? 'bg-gray-200 text-gray-700' : 'bg-dkt-brand-blue text-white hover:bg-blue-900'
                    }`}
                >
                    Anterior
                </button>
                <button
                    onClick={() => onChange(page + 1)}
                    disabled={nextButtonDisabled}
                    className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 ${
                        nextButtonDisabled ? 'bg-gray-200 text-gray-700' : 'bg-dkt-brand-blue text-white hover:bg-blue-900'
                    }`}
                >
                    Siguiente
                </button>
            </div>
        </nav>
    )
}

export default Pagination