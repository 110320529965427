import NewProviderModal from "v2/components/NewProviderModal";
import Login from "pages/Login/Login";
import UploadProvidersModal from "v2/components/UploadProvidersModal";
import UserAccessModal from "v2/components/UserAccessModal";
import Providers from "v2/pages/providers/Providers";
import { useState } from "react";
import useSession from "hooks/useSession";
import Header from "v2/components/Header/Header";
import Information from "v2/components/Information/Information";
import { Toaster } from "react-hot-toast";

function App() {

  const [showNewProviderModal, setNewProviderModal] = useState(false)
  const [uploadProvidersModal, setUploadProvidersModal] = useState(false)
  const [userAccessModal, setUserAccessModal] = useState(false)

  const { user } = useSession()

  if (!user) return <Login />

  return (
    <>
      <Header />

      <Toaster />

      <div className="border-b border-black bg-dkt-brand-blue">
        <div className="md:flex md:items-center md:justify-between gap-x-6 p-6 lg:px-8 max-w-7xl mx-auto">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">
              Directorio de proveedores
            </h2>
          </div>
          {
            user.role &&
            <div className="mt-4 flex md:ml-4 md:mt-0">
              <button
                type="button"
                onClick={() => setUploadProvidersModal(true)}
                className="ml-3 inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-dkt-background-alternative"
              >
                Creación masiva
              </button>
              <button
                type="button"
                onClick={() => setNewProviderModal(true)}
                className="ml-3 inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-dkt-background-alternative"
              >
                Nuevo proveedor
              </button>
              <button
                type="button"
                onClick={() => setUserAccessModal(true)}
                className="ml-3 inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-dkt-background-alternative"
              >
                Accesos
              </button>
            </div>
          }
        </div>
      </div>

      <Information />

      <Providers />

      {uploadProvidersModal && <UploadProvidersModal onClose={() => setUploadProvidersModal(false)} />}
      {showNewProviderModal && <NewProviderModal onClose={() => setNewProviderModal(false)} />}
      {userAccessModal && <UserAccessModal onClose={() => setUserAccessModal(false)} />}
    </>
  )
}


{/* <div className="flex flex-col justify-center h-screen relative w-screen overflow-hidden">
      <div className="sticky left-0 right-0 top-0 p-4 bg-gray-800 z-10 flex items-center justify-between">
        <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">Proveedores Decathlon</h2>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <div className="text-white flex items-center">
            <p>{user.id}</p>
            <button className="mx-2 focus:outline-none" onClick={logout}><LogoutIcon className="h-5" /></button>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto w-full h-full bg-gray-50">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pb-14 pt-6 ">
          <Providers />
        </div>
      </div>
      {
        user.role &&
        <div className="absolute bottom-8 right-4 md:bottom-10 md:right-10 flex flex-col space-y-2 ">
          <button
            onClick={() => setUploadProvidersModal(true)}
            aria-label="Crear un proveedor"
            className=" bg-blue-500 p-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ring-offset-2">
            <UserGroupIcon className="h-8 text-white" />
          </button>
          <button
            onClick={() => setNewProviderModal(true)}
            aria-label="Crear un proveedor"
            className=" bg-blue-500 p-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ring-offset-2">
            <PlusIcon className="h-8 text-white" />
          </button>
          <button
            onClick={() => setUserAccessModal(true)}
            aria-label="Crear un proveedor"
            className=" bg-blue-500 p-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ring-offset-2">
            <UserAddIcon className="h-8 text-white" />
          </button>
        </div>
      }
      <UserAccessModal visible={userAccessModal} onClose={() => setUserAccessModal(false)} />
      <UploadProvidersModal visible={uploadProvidersModal} onClose={() => setUploadProvidersModal(false)} />
      <NewProviderModal visible={showNewProviderModal} onClose={() => setNewProviderModal(false)} />
    </div> */}

export default App;
