import queryKeys from "config/queries"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useMutation, useQueryClient } from "react-query"
import User from "types/User"
import axios from "utils/axios"

interface UserAccessModalProps {
    onClose: () => void
}

const UserAccessModal = ({ onClose }: UserAccessModalProps) => {

    const { register, handleSubmit, formState: { errors }, reset: resetForm } = useForm({
        reValidateMode: "onBlur"
    })

    const queryClient = useQueryClient()

    const { mutate, isLoading, isError, reset } = useMutation((newUserAccess: User) => axios.post('users/', newUserAccess), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.USER_ACCESS)
            toast.dismiss()
            toast.success("Has otorgado un nuevo acceso administrador.", { style: { background: "#3643BA", color: "white" } })
            handleClose()
        },
        onError: () => {
            toast.dismiss()
            toast.error("No fue posible otorgar el acceso administrador, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        },
        onMutate: () => {
          toast.loading("Agregando un nuevo acceso administrador.", { style: { background: "#7AFFA6", color: "white" } })
        }
    })

    const createUserAccess = (newUserAccess: User) => mutate(newUserAccess)

    const onSubmit = async (data: any) => {
        createUserAccess({ ...data, role: "admin" })
    }

    const handleClose = () => {
        onClose()
        resetForm()
        reset()
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white shadow-lg max-w-md w-full p-6 relative">
                <h2 className="absolute top-0 left-0 w-full text-lg font-semibold text-white bg-dkt-brand-blue p-6">
                    Configurar acceso de administrador
                </h2>

                <p className="mt-16 text-gray-700">
                    Ingresa el ID Decathlon al que quieres dar acceso
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Id Decathlon:
                        </label>
                        <input
                            {...register("id", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Ejemplo: IGONZA38"
                        />
                        {errors.id && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>

                    <div className="mt-6 flex justify-end">
                        <button
                            disabled={isLoading}
                            onClick={handleClose}
                            className="px-4 py-2 bg-gray-200 text-gray-800 hover:bg-gray-300"
                        >
                            Cerrar
                        </button>
                        <button className="ml-2 px-4 py-2 bg-dkt-brand-blue text-white hover:bg-blue-700"
                            type="submit"
                        >
                            Confirmar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UserAccessModal