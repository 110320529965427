import queryKeys from "config/queries"
import Provider from "types/Provider"
import { useQueryClient, useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast';
import axios from 'utils/axios'

interface UserAccessModalProps {
    onClose: () => void
}

const NewProviderModal = ({ onClose }: UserAccessModalProps) => {

    const { register, handleSubmit, formState: { errors }, reset: resetForm, control } = useForm({
        reValidateMode: "onBlur",
    })

    const queryClient = useQueryClient()

    const { mutate, isLoading, isError, reset } = useMutation((newProvider: Provider) => axios.post('providers/', newProvider), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.PROVIDERS)
            toast.dismiss()
            toast.success("Has agregado un nuevo proveedor.", { style: { background: "#3643BA", color: "white" } })
            handleClose()
        },
        onError: () => {
            toast.dismiss()
            toast.error("No fue posible agregar el proveedor, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        },
        onMutate: () => {
          toast.loading("Agregando un proveedor.", { style: { background: "#7AFFA6", color: "white" } })
        }
    })

    const createNewProvider = (newProvider: Provider) => mutate(newProvider)

    const onSubmit = async (data: Provider) => {
        createNewProvider({ ...data })
    }

    const handleClose = () => {
        onClose()
        resetForm()
        reset()

    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow-lg max-w-2xl w-full p-6 max-h-[90vh] overflow-y-auto relative">
                <h2 className="absolute top-0 left-0 w-full text-lg font-semibold text-white bg-dkt-brand-blue p-8">
                    Nuevo proveedor
                </h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-24">
                    <div>
                        <label
                            htmlFor="code"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Código
                        </label>
                        <input
                            {...register("code", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-dkt-brand-blue focus:border-dkt-brand-blue"
                            placeholder={`Código de proveedor`}
                        />
                        {errors.code && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>
                    <div>
                        <label
                            htmlFor="nit"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Nit
                        </label>
                        <input
                            {...register("nit", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-dkt-brand-blue focus:border-dkt-brand-blue"
                            placeholder={`Nit`}
                        />
                        {errors.nit && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="legalName"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Razón Social
                        </label>
                        <input
                            {...register("legalName", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-dkt-brand-blue focus:border-dkt-brand-blue"
                            placeholder={`Razón social`}
                        />
                        {errors.legalName && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>
                    <div>
                        <label
                            htmlFor="tradingname"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Nombre comercial
                        </label>
                        <input
                            {...register("tradingname", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-dkt-brand-blue focus:border-dkt-brand-blue"
                            placeholder={`Nombre comercial`}
                        />
                        {errors.tradingname && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>
                    <div>
                        <label
                            htmlFor="city"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Ciudad
                        </label>
                        <input
                            {...register("city", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-dkt-brand-blue focus:border-dkt-brand-blue"
                            placeholder={`Ciudad`}
                        />
                        {errors.city && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>
                    <div>
                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Teléfono
                        </label>
                        <input
                            {...register("phone", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-dkt-brand-blue focus:border-dkt-brand-blue"
                            placeholder={`Teléfono`}
                        />
                        {errors.phone && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>
                    <div>
                        <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Categoría
                        </label>
                        <input
                            {...register("category", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-dkt-brand-blue focus:border-dkt-brand-blue"
                            placeholder={`Categoría`}
                        />
                        {errors.category && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Descripción
                        </label>
                        <input
                            {...register("description", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-dkt-brand-blue focus:border-dkt-brand-blue"
                            placeholder={`Descripción`}
                        />
                        {errors.description && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="agreements"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Convenios
                        </label>
                        <input
                            {...register("agreements", { required: true })}
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-dkt-brand-blue focus:border-dkt-brand-blue"
                            placeholder={`Convenios`}
                        />
                        {errors.agreements && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    </div>
                </div>

                <div className="mt-4 flex items-center">
                    <label className="block text-sm font-medium text-gray-700 mr-4">
                        Estado:
                    </label>
                    <input
                        {...register("status")}
                        type="checkbox"
                        name="status"
                        className="w-5 h-5 text-dkt-brand-blue border-gray-300 focus:ring-dkt-brand-blue"
                    />
                </div>

                <div className="mt-6 flex justify-end">
                    <button
                        disabled={isLoading}
                        onClick={handleClose}
                        className="px-4 py-2 bg-gray-200 text-gray-800 hover:bg-gray-300"
                    >
                        Cerrar
                    </button>
                    <button
                        type="submit"
                        className="ml-2 px-4 py-2 bg-dkt-brand-blue text-white hover:bg-blue-700"
                    >
                        Confirmar
                    </button>
                </div>
            </form>
        </div>
    )
}

export default NewProviderModal