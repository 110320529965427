const Information = () => {
    return (
        <div className="border-b border-gray-200 gap-x-6 p-6 lg:px-8 max-w-7xl mx-auto">
            <h3 className="text-lg/8 text-gray-700">
                <p>
                    <span className="font-medium">A tener en cuenta: </span>
                    En caso de no encontrar tu proveedor en el buscador, él te dará la opción
                    <span className="font-medium">{" "}¨AQUÍ¨{" "}</span>
                    donde te llevara a un forms el cual debes diligenciar según lo indicado.
                </p>
                <p className="mt-2" >El directorio se actualizara semana a semana, los reembolso deben ser cargados con el tercero solo si cuentas con una 
                    "factura electronica o en su defecto que tenga CUFE y código QR a nombre de Decathlon o Decoam" en caso de contar con otro soporte no dudes 
                    en escribirle a tu Accounting. Adicional queremos recomendarles no cambiar el correo de contabilidad en los establecimientos, ya que necesitamos aprobarlas y 
                    validarlas en la DIAN y así mismo no perder la información, el correo de contabilidad es el siguiente <span className="font-medium">contabilidad.colombia@decathlon.com</span></p>
            </h3>
            <p className="mt-2 max-w-4xl text-base font-semibold italic text-dkt-brand-blue">
                La información de los proveedores es confidencial y no se debe compartir con externos.
            </p>
        </div>
    )
}

export default Information