import Provider from "types/Provider"
import ProviderListItem from "./ProviderListItem"
import Spinner from "components/Spinner/Spinner"
import { EmojiSadIcon } from "@heroicons/react/outline"
import { useState } from "react"
import DescriptionProvidersModal from "v2/components/DescriptionProvidersModal"
import AgreementsProvidersModal from "v2/components/AgreementsProvidersModal"

interface ProvidersListProps {
    providers: Array<Provider> | undefined
}
const ProvidersList = ({ providers }: ProvidersListProps) => {

    const [userDescriptionProvidersModal, setDescriptionProvidersModal] = useState(false)
    const [userAgreementsProvidersModal, setAgreementsProvidersModal] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);

    //Abrir el modal con la descripción del proveedor seleccionado
    const openModalDescriptionProviders = (provider: Provider) => {
        setSelectedProvider(provider);
        setDescriptionProvidersModal(true);
    };

    //Cerrar el modal con la descripción del proveedor seleccionado
    const closeModalDescriptionProviders = () => {
        setDescriptionProvidersModal(false);
        setSelectedProvider(null);
    };

    //Abrir el modal con los convenios del proveedor seleccionado
    const openModalAgreementsProviders = (provider: Provider) => {
        setSelectedProvider(provider);
        setAgreementsProvidersModal(true);
    };

    //Abrir el modal con los convenios del proveedor seleccionado
    const closeModalAgreementsProviders = () => {
        setAgreementsProvidersModal(false);
        setSelectedProvider(null);
    };

    return (
        <div className="gap-x-6 p-6 lg:px-8 max-w-7xl mx-auto">
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Código
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Razón social / Nit
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Estado
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Ciudad
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Teléfono
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Categoría
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Descripción
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Condiciones de pago
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {
                                    !providers && <tr>
                                        <td colSpan={7} >
                                            <div className="flex justify-center w-full text-blue-500 py-4">
                                                <Spinner size="medium" />
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {
                                    providers && providers.length === 0 && <tr>
                                        <td colSpan={7}>
                                            <p className="text-gray-500 p-4 flex items-center text-sm"><EmojiSadIcon className="mr-2 h-4" />No hay proveedor para este filtro. Ingresa <a href="https://docs.google.com/forms/d/e/1FAIpQLSdEykevlEmZIdCDeuKDicA1O__-gKP9WFK8heZZsPhO_kmZLA/viewform?gxids=7628" className="px-1 underline font-bold" target="_blank"> aquí</a> para solicitar la creación de uno nuevo.</p>

                                        </td>
                                    </tr>
                                }
                                {
                                    providers && providers.length > 0 && providers.map((provider) => (
                                        <ProviderListItem key={provider.code} provider={provider} openModalDescriptionProviders={openModalDescriptionProviders} openModalAgreementsProviders={openModalAgreementsProviders} />
                                    ))
                                }
                            </tbody>
                        </table>
                        {userDescriptionProvidersModal && <DescriptionProvidersModal onClose={closeModalDescriptionProviders} provider={selectedProvider} />}
                        {userAgreementsProvidersModal && <AgreementsProvidersModal onClose={closeModalAgreementsProviders} provider={selectedProvider} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProvidersList