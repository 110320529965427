import Provider from "types/Provider";

interface DescriptionProvidersModalProps {
    onClose: () => void;
    provider: Provider | null;
}

const DescriptionProvidersModal = ({ provider, onClose }: DescriptionProvidersModalProps) => {

    if (!provider) return null;  // Si no hay proveedor, no mostramos el modal

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white shadow-lg max-w-md w-full p-6 relative">
                <h2 className="absolute top-0 left-0 w-full text-lg font-semibold text-white bg-dkt-brand-blue p-6">
                    {provider.legalName}
                </h2>

                {/* Contenido */}
                <div className="mt-20 border-l-4 border-dkt-brand-blue bg-blue-50 p-4">
                    <div className="flex">
                        <div className="shrink-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-blue-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 3a7 7 0 1 1 0 14 7 7 0 0 1 0-14zm0 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm0 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-blue-700">
                               {provider.description ? provider.description : "Aun no hay una descripción disponible para este proveedor"}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Botón Cerrar */}
                <div className="mt-6 flex justify-end">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-200 text-gray-800 hover:bg-gray-300"
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>

    )
}

export default DescriptionProvidersModal