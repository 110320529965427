import Provider from "types/Provider"

interface ProviderListItemProps {
    provider: Provider
    openModalDescriptionProviders: (provider: Provider) => void;
    openModalAgreementsProviders: (provider: Provider) => void;
}

const ProviderListItem = ({ provider, openModalDescriptionProviders }: ProviderListItemProps) => {
    return (
        <>
            <tr key={provider.code}>
                <td className="whitespace-nowrap py-2 text-sm text-gray-500">{provider.code}</td>
                <td className="px-3 py-2 text-sm text-gray-500">
                    <div className="text-gray-900">{provider.legalName}</div>
                    <div className="mt-1 text-gray-500">{provider.tradingname && `Nombre comercial: ${provider.tradingname}`}</div>
                    <div className="mt-1 text-gray-500">Nit: {provider.nit}</div>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm">
                    <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ${provider.status ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
                        }`}>
                        {provider.status ? "Activo" : "Inactivo"}
                    </span>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{provider.city ? provider.city : "Sin información"}</td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{provider.phone ? provider.phone : "Sin información"}</td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{provider.category ? provider.category : "Sin información"}</td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                    <button className="text-indigo-600 hover:text-indigo-900" type="button"
                        onClick={() => openModalDescriptionProviders(provider)} >
                        Detalles<span className="sr-only">, {provider.description}</span>
                    </button>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{provider.agreements ? provider.agreements + " días" : "Sin información"}</td>
            </tr>
        </>
    )
}

export default ProviderListItem


